module.exports = {
  foundation: {
    "font-primary": {
      "150-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "16",
          fontSize: "10",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "150-medium": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Medium",
          lineHeight: "16",
          fontSize: "10",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "150-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "16",
          fontSize: "10",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "200-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "20",
          fontSize: "12",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "200-medium": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Medium",
          lineHeight: "20",
          fontSize: "12",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "200-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "20",
          fontSize: "12",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "250-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "20",
          fontSize: "14",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "250-medium": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Medium",
          lineHeight: "20",
          fontSize: "14",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "250-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "20",
          fontSize: "14",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "300-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "24",
          fontSize: "16",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "300-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "24",
          fontSize: "16",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "350-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "32",
          fontSize: "20",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "350-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "32",
          fontSize: "20",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "400-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "36",
          fontSize: "24",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "400-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "36",
          fontSize: "24",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "450-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "42",
          fontSize: "28",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "450-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "42",
          fontSize: "28",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "500-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "48",
          fontSize: "32",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "500-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "48",
          fontSize: "32",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "550-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "60",
          fontSize: "40",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "550-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "60",
          fontSize: "40",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "600-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "72",
          fontSize: "48",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "600-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "72",
          fontSize: "48",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "650-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "84",
          fontSize: "56",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "650-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "84",
          fontSize: "56",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "700-bold": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Semi Bold",
          lineHeight: "96",
          fontSize: "64",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
      "700-regular": {
        value: {
          fontFamily: "Inter",
          fontWeight: "Regular",
          lineHeight: "96",
          fontSize: "64",
          letterSpacing: "0%",
          paragraphSpacing: "0",
          textDecoration: "none",
          textCase: "none",
        },
        type: "typography",
      },
    },
    space: {
      25: {
        value: "2",
        type: "spacing",
      },
      50: {
        value: "4",
        type: "spacing",
      },
      100: {
        value: "8",
        type: "spacing",
      },
      125: {
        value: "10",
        type: "spacing",
      },
      150: {
        value: "12",
        type: "spacing",
      },
      200: {
        value: "16",
        type: "spacing",
      },
      225: {
        value: "20",
        type: "spacing",
      },
      250: {
        value: "24",
        type: "spacing",
      },
      300: {
        value: "32",
        type: "spacing",
      },
    },
    "font-family": {
      primary: {
        value: "Inter",
        type: "fontFamilies",
      },
      secondary: {
        value: "Campton",
        type: "fontFamilies",
      },
      tertiary: {
        value: "Monosten",
        type: "fontFamilies",
      },
    },
    "font-weight": {
      primary: {
        regular: {
          value: "Regular",
          type: "fontWeights",
        },
        medium: {
          value: "Medium",
          type: "fontWeights",
        },
        bold: {
          value: "Semi Bold",
          type: "fontWeights",
        },
      },
      secondary: {
        regular: {
          value: "Medium",
          type: "fontWeights",
        },
        bold: {
          value: "SemiBold",
          type: "fontWeights",
        },
      },
      tertiary: {
        regular: {
          value: "Regular",
          type: "fontWeights",
        },
        bold: {
          value: "Bold",
          type: "fontWeights",
        },
      },
    },
    color: {
      brand: {
        "gray-800": {
          value: "#19191b",
          type: "color",
        },
        "gray-600": {
          value: "#404042",
          type: "color",
        },
        "gray-400": {
          value: "#6E7071",
          type: "color",
        },
        "gray-200": {
          value: "#a5a5a5",
          type: "color",
        },
        "gray-100": {
          value: "#E6E8EB",
          type: "color",
        },
        "gray-50": {
          value: "#F9F9F9",
          type: "color",
        },
        "green-darkest": {
          value: "#155C52",
          type: "color",
          description: "UVS",
        },
        "green-dark": {
          value: "#329F6F",
          type: "color",
          description: "UVS",
        },
        green: {
          value: "#60C88D",
          type: "color",
          description: "UVS",
        },
        "green-light": {
          value: "#A6E1B1",
          type: "color",
          description: "UVS",
        },
        "blue-darkest": {
          value: "#194668",
          type: "color",
        },
        "blue-dark": {
          value: "#458FAB",
          type: "color",
        },
        blue: {
          value: "#8BC9DE",
          type: "color",
        },
        "blue-light": {
          value: "#B9DFEA",
          type: "color",
        },
        "yellow-darkest": {
          value: "#8E6D3F",
          type: "color",
        },
        "yellow-dark": {
          value: "#BD9B44",
          type: "color",
        },
        yellow: {
          value: "#EED366",
          type: "color",
        },
        "yellow-light": {
          value: "#F5E4A5",
          type: "color",
        },
        "red-darkest": {
          value: "#613018",
          type: "color",
        },
        "red-dark": {
          value: "#904B2C",
          type: "color",
        },
        red: {
          value: "#BF6640",
          type: "color",
        },
        "red-light": {
          value: "#E09361",
          type: "color",
        },
        "purple-darkest": {
          value: "#503E5C",
          type: "color",
        },
        "purple-dark": {
          value: "#8E70A8",
          type: "color",
        },
        purple: {
          value: "#BAA2CD",
          type: "color",
        },
        "purple-light": {
          value: "#DED0EC",
          type: "color",
        },
      },
      white: {
        value: "#ffffff",
        type: "color",
      },
      black: {
        value: "#000000",
        type: "color",
      },
      "elevation-shadow": {
        value: "rgb(0,0,0,0.12)",
        type: "color",
      },
      gray: {
        50: {
          value: "#FAFAFA",
          type: "color",
        },
        100: {
          value: "#EDEDED",
          type: "color",
        },
        200: {
          value: "#d4d4d4",
          type: "color",
          description: "Placeholder value, needs UVS definition",
        },
        300: {
          value: "#bcbcbc",
          type: "color",
          description: "Placeholder value, needs official UVS definition",
        },
        400: {
          value: "#A4A4A4",
          type: "color",
        },
        500: {
          value: "#8B8B8B",
          type: "color",
        },
        600: {
          value: "#727272",
          type: "color",
        },
        700: {
          value: "#5F5F5F",
          type: "color",
        },
        800: {
          value: "#4B4B4B",
          type: "color",
        },
        900: {
          value: "#3B3B3B",
          type: "color",
        },
        1000: {
          value: "#181818",
          type: "color",
        },
      },
      red: {
        50: {
          value: "#FCF9F5",
          type: "color",
        },
        100: {
          value: "#F9EAE2",
          type: "color",
        },
        200: {
          value: "#F0CEB7",
          type: "color",
        },
        300: {
          value: "#E7B08C",
          type: "color",
        },
        400: {
          value: "#E09361",
          type: "color",
        },
        500: {
          value: "#BF6640",
          type: "color",
        },
        600: {
          value: "#AB5C39",
          type: "color",
        },
        700: {
          value: "#904B2C",
          type: "color",
        },
        800: {
          value: "#733C22",
          type: "color",
        },
        900: {
          value: "#5D2D17",
          type: "color",
        },
      },
      yellow: {
        50: {
          value: "#FDFAEE",
          type: "color",
        },
        100: {
          value: "#F9EEC5",
          type: "color",
        },
        200: {
          value: "#EED366",
          type: "color",
        },
        300: {
          value: "#D7BA5A",
          type: "color",
        },
        400: {
          value: "#C1A14E",
          type: "color",
        },
        500: {
          value: "#A9863C",
          type: "color",
        },
        600: {
          value: "#8E6D3F",
          type: "color",
        },
        700: {
          value: "#755A34",
          type: "color",
        },
        800: {
          value: "#5D4729",
          type: "color",
        },
        900: {
          value: "#493821",
          type: "color",
        },
      },
      green: {
        50: {
          value: "#F5FCF6",
          type: "color",
        },
        100: {
          value: "#DCF3E1",
          type: "color",
        },
        200: {
          value: "#A9E2B3",
          type: "color",
        },
        300: {
          value: "#76CE98",
          type: "color",
        },
        400: {
          value: "#52B784",
          type: "color",
        },
        500: {
          value: "#319D6D",
          type: "color",
        },
        600: {
          value: "#1B815F",
          type: "color",
        },
        700: {
          value: "#0E6C52",
          type: "color",
        },
        800: {
          value: "#13544B",
          type: "color",
        },
        900: {
          value: "#0F433B",
          type: "color",
        },
      },
      blue: {
        50: {
          value: "#F6FBFC",
          type: "color",
        },
        100: {
          value: "#DFF0F6",
          type: "color",
        },
        200: {
          value: "#B9DFEA",
          type: "color",
        },
        300: {
          value: "#8BC9DE",
          type: "color",
        },
        400: {
          value: "#6AB0CA",
          type: "color",
        },
        500: {
          value: "#4D93AF",
          type: "color",
        },
        600: {
          value: "#3D7A9A",
          type: "color",
        },
        700: {
          value: "#306385",
          type: "color",
        },
        800: {
          value: "#234E6F",
          type: "color",
        },
        900: {
          value: "#163E5B",
          type: "color",
        },
      },
      purple: {
        50: {
          value: "#FBF9FD",
          type: "color",
        },
        100: {
          value: "#F2EBF7",
          type: "color",
        },
        200: {
          value: "#E0CEE9",
          type: "color",
        },
        300: {
          value: "#CFB7DD",
          type: "color",
        },
        400: {
          value: "#BAA2CD",
          type: "color",
        },
        500: {
          value: "#A489BB",
          type: "color",
        },
        600: {
          value: "#8E70A8",
          type: "color",
        },
        700: {
          value: "#6F5782",
          type: "color",
        },
        800: {
          value: "#503E5C",
          type: "color",
        },
        900: {
          value: "#413349",
          type: "color",
        },
      },
      error: {
        50: {
          value: "#FEF9F8",
          type: "color",
        },
        100: {
          value: "#FCEAE7",
          type: "color",
        },
        200: {
          value: "#F5CBC5",
          type: "color",
        },
        300: {
          value: "#ECADA5",
          type: "color",
        },
        400: {
          value: "#E18E85",
          type: "color",
        },
        500: {
          value: "#D36D64",
          type: "color",
        },
        600: {
          value: "#C34A45",
          type: "color",
        },
        700: {
          value: "#B4272C",
          type: "color",
        },
        800: {
          value: "#980C1D",
          type: "color",
        },
        900: {
          value: "#761318",
          type: "color",
        },
      },
      cream: {
        50: {
          value: "#FBFAF8",
          type: "color",
        },
        100: {
          value: "#F2EDE7",
          type: "color",
        },
        200: {
          value: "#DBD3CB",
          type: "color",
        },
        300: {
          value: "#C2BBB4",
          type: "color",
        },
        400: {
          value: "#A9A39D",
          type: "color",
        },
        500: {
          value: "#8F8A85",
          type: "color",
        },
        600: {
          value: "#75716D",
          type: "color",
        },
        700: {
          value: "#625E5B",
          type: "color",
        },
        800: {
          value: "#4D4A47",
          type: "color",
        },
        900: {
          value: "#3D3B38",
          type: "color",
        },
      },
    },
    radius: {
      50: {
        value: "4",
        type: "borderRadius",
      },
      100: {
        value: "8",
        type: "borderRadius",
      },
      125: {
        value: "10",
        type: "borderRadius",
      },
      200: {
        value: "16",
        type: "borderRadius",
      },
      250: {
        value: "24",
        type: "borderRadius",
      },
      300: {
        value: "32",
        type: "borderRadius",
      },
      full: {
        value: "999",
        type: "borderRadius",
      },
    },
    elevation: {
      0: {
        value: {
          x: "0",
          y: "0",
          blur: "0",
          spread: "0",
          color: "{color.black}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 0",
      },
      100: {
        value: {
          x: "0",
          y: "1",
          blur: "1",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 1",
      },
      200: {
        value: {
          x: "0",
          y: "1",
          blur: "2",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 2",
      },
      300: {
        value: {
          x: "0",
          y: "1",
          blur: "4",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 3",
      },
      400: {
        value: {
          x: "0",
          y: "2",
          blur: "5",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 4",
      },
      500: {
        value: {
          x: "0",
          y: "3",
          blur: "10",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 6",
      },
      600: {
        value: {
          x: "0",
          y: "4",
          blur: "10",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 8",
      },
      700: {
        value: {
          x: "0",
          y: "6",
          blur: "17",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 12",
      },
      800: {
        value: {
          x: "0",
          y: "8",
          blur: "24",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 16",
      },
      900: {
        value: {
          x: "0",
          y: "12",
          blur: "38",
          spread: "0",
          color: "{color.elevation-shadow}",
          type: "dropShadow",
        },
        type: "boxShadow",
        description: "Material Elevation 24",
      },
    },
    "border-width": {
      100: {
        value: "1",
        type: "borderWidth",
      },
      150: {
        value: "1.5",
        type: "borderWidth",
      },
      200: {
        value: "2",
        type: "borderWidth",
      },
      300: {
        value: "4",
        type: "borderWidth",
      },
    },
    "font-decoration": {
      underline: {
        value: "underline",
        type: "textDecoration",
      },
      none: {
        value: "none",
        type: "textDecoration",
      },
    },
    "font-size": {
      150: {
        value: "10",
        type: "fontSizes",
      },
      200: {
        value: "12",
        type: "fontSizes",
      },
      250: {
        value: "14",
        type: "fontSizes",
      },
      300: {
        value: "16",
        type: "fontSizes",
      },
      350: {
        value: "20",
        type: "fontSizes",
      },
      400: {
        value: "24",
        type: "fontSizes",
      },
      450: {
        value: "28",
        type: "fontSizes",
      },
      500: {
        value: "32",
        type: "fontSizes",
      },
      550: {
        value: "40",
        type: "fontSizes",
      },
      600: {
        value: "48",
        type: "fontSizes",
      },
      650: {
        value: "56",
        type: "fontSizes",
      },
      700: {
        value: "64",
        type: "fontSizes",
      },
    },
    "line-height": {
      150: {
        value: "16",
        type: "lineHeights",
      },
      200: {
        value: "20",
        type: "lineHeights",
      },
      250: {
        value: "20",
        type: "lineHeights",
      },
      300: {
        value: "24",
        type: "lineHeights",
      },
      350: {
        value: "32",
        type: "lineHeights",
      },
      400: {
        value: "36",
        type: "lineHeights",
      },
      450: {
        value: "42",
        type: "lineHeights",
      },
      500: {
        value: "48",
        type: "lineHeights",
      },
      550: {
        value: "60",
        type: "lineHeights",
      },
      600: {
        value: "72",
        type: "lineHeights",
      },
      650: {
        value: "84",
        type: "lineHeights",
      },
      700: {
        value: "96",
        type: "lineHeights",
      },
    },
    "font-case": {
      uppercase: {
        value: "uppercase",
        type: "textCase",
      },
    },
  },
  pro: {
    semantic: {
      "font-primary": {
        "small-regular": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "small-regular-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        "small-medium": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Medium",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "small-medium-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Medium",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        "small-bold": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "small-bold-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "20",
            fontSize: "12",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-regular": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-regular-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-medium": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Medium",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-medium-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Medium",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-bold": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "standard-bold-underline": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "20",
            fontSize: "14",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "underline",
            textCase: "none",
          },
          type: "typography",
        },
        large: {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "24",
            fontSize: "16",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "large-bold": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "24",
            fontSize: "16",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "x-large": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Regular",
            lineHeight: "32",
            fontSize: "20",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
        "x-large-bold": {
          value: {
            fontFamily: "Inter",
            fontWeight: "Semi Bold",
            lineHeight: "32",
            fontSize: "20",
            letterSpacing: "0%",
            paragraphSpacing: "0",
            textDecoration: "none",
            textCase: "none",
          },
          type: "typography",
        },
      },
    },
    color: {
      foreground: {
        primary: {
          value: "{color.brand.gray-800}",
          type: "color",
        },
        "primary-on-dark": {
          value: "{color.white}",
          type: "color",
        },
        secondary: {
          value: "{color.brand.gray-400}",
          type: "color",
        },
        disabled: {
          value: "{color.brand.gray-200}",
          type: "color",
        },
        "accessibility-focus": {
          value: "#0000FF",
          type: "color",
        },
        "view-controller": {
          value: "{color.blue.900}",
          type: "color",
        },
      },
      background: {
        primary: {
          value: "{color.white}",
          type: "color",
        },
        "primary-hover": {
          value: "{color.brand.gray-100}",
          type: "color",
        },
        secondary: {
          value: "{color.brand.gray-50}",
          type: "color",
        },
        "dark-primary": {
          value: "{color.brand.gray-800}",
          type: "color",
        },
      },
      status: {
        success: {
          value: "{color.brand.green-dark}",
          type: "color",
        },
        error: {
          value: "{color.error.700}",
          type: "color",
        },
      },
    },
    space: {
      between: {
        coupled: {
          value: "{space.50}",
          type: "spacing",
        },
        related: {
          value: "{space.100}",
          type: "spacing",
        },
        standard: {
          value: "{space.200}",
          type: "spacing",
        },
        padded: {
          value: "{space.250}",
          type: "spacing",
        },
        separated: {
          value: "{space.300}",
          type: "spacing",
        },
      },
      around: {
        "xx-small": {
          value: "{space.25}",
          type: "spacing",
        },
        "x-small": {
          value: "{space.50}",
          type: "spacing",
        },
        "x-small-squish-vertical": {
          value: "{space.50}",
          type: "spacing",
        },
        "x-small-squish-horizontal": {
          value: "{space.100}",
          type: "spacing",
        },
        small: {
          value: "{space.100}",
          type: "spacing",
        },
        "small-squish-vertical": {
          value: "{space.100}",
          type: "spacing",
        },
        "small-squish-horizontal": {
          value: "{space.150}",
          type: "spacing",
        },
        standard: {
          value: "{space.200}",
          type: "spacing",
        },
        "standard-squish-vertical": {
          value: "{space.200}",
          type: "spacing",
        },
        "standard-squish-horizontal": {
          value: "{space.250}",
          type: "spacing",
        },
      },
    },
    "font-size": {
      small: {
        value: "{font-size.200}",
        type: "fontSizes",
      },
      standard: {
        value: "{font-size.250}",
        type: "fontSizes",
      },
      large: {
        value: "{font-size.300}",
        type: "fontSizes",
      },
      "x-large": {
        value: "{font-size.350}",
        type: "fontSizes",
      },
    },
    "line-height": {
      small: {
        value: "{line-height.200}",
        type: "lineHeights",
      },
      standard: {
        value: "{line-height.250}",
        type: "lineHeights",
      },
      large: {
        value: "{line-height.300}",
        type: "lineHeights",
      },
      "x-large": {
        value: "{line-height.350}",
        type: "lineHeights",
      },
    },
    "border-radius": {
      default: {
        value: "{radius.100}",
        type: "borderRadius",
      },
    },
  },
  "pro-app": {
    color: {
      stroke: {
        input: {
          default: {
            value: "{color.brand.gray-200}",
            type: "color",
          },
          hover: {
            value: "{color.brand.gray-400}",
            type: "color",
          },
          active: {
            value: "{color.brand.blue-dark}",
            type: "color",
          },
          error: {
            value: "{color.error.600}",
            type: "color",
          },
        },
        divider: {
          default: {
            value: "{color.gray.400}",
            type: "color",
          },
        },
      },
      foreground: {
        "view-controller": {
          active: {
            value: "{color.blue.900}",
            type: "color",
          },
        },
        "segmented-control": {
          default: {
            value: "{color.foreground.view-controller}",
            type: "color",
          },
        },
      },
      background: {
        "toggle-track": {
          default: {
            value: "{color.gray.400}",
            type: "color",
          },
          active: {
            value: "{color.blue.900}",
            type: "color",
          },
        },
        "toggle-switch": {
          default: {
            value: "{color.white}",
            type: "color",
          },
        },
        "segmented-control-tab": {
          active: {
            value: "{color.background.primary}",
            type: "color",
          },
          hover: {
            value: "{color.background.primary-hover}",
            type: "color",
          },
        },
        "segmented-control-container": {
          default: {
            value: "{color.background.secondary}",
            type: "color",
          },
        },
      },
    },
    "border-width": {
      input: {
        default: {
          value: "{border-width.100}",
          type: "borderWidth",
        },
        bold: {
          value: "{border-width.200}",
          type: "borderWidth",
        },
      },
    },
    "border-radius": {
      card: {
        default: {
          value: "{border-radius.default}",
          type: "borderRadius",
        },
      },
      "segmented-control": {
        container: {
          value: "{radius.125}",
          type: "borderRadius",
        },
        selection: {
          value: "{border-radius.default}",
          type: "borderRadius",
        },
      },
    },
  },
  consumer: {
    "border-radius": {
      default: {
        value: "{radius.200}",
        type: "borderRadius",
      },
    },
    color: {
      foreground: {
        primary: {
          value: "{color.gray.1000}",
          type: "color",
        },
        "on-dark": {
          value: "{color.white}",
          type: "color",
        },
      },
      background: {
        primary: {
          value: "{color.white}",
          type: "color",
        },
        secondary: {
          value: "{color.gray.100}",
          type: "color",
        },
        dark: {
          value: "{color.gray.1000}",
          type: "color",
        },
      },
      button: {
        background: {
          generative: {
            default: {
              value: "{color.green.600}",
              type: "color",
            },
            hover: {
              value: "{color.green.800}",
              type: "color",
            },
          },
          primary: {
            default: {
              value: "{color.foreground.primary}",
              type: "color",
            },
          },
          secondary: {
            default: {
              value: "{color.background.secondary}",
              type: "color",
            },
          },
        },
      },
    },
    "border-width": {
      small: {
        value: "{border-width.100}",
        type: "borderWidth",
      },
      medium: {
        value: "{border-width.150}",
        type: "borderWidth",
      },
      large: {
        value: "{border-width.200}",
        type: "borderWidth",
      },
    },
  },
  "consumer-app": {
    "font-size": {
      "x-small": {
        value: "{font-size.200}",
        type: "fontSizes",
      },
      small: {
        value: "{font-size.250}",
        type: "fontSizes",
      },
      standard: {
        value: "{font-size.300}",
        type: "fontSizes",
      },
      large: {
        value: "{font-size.350}",
        type: "fontSizes",
      },
      "x-large": {
        value: "{font-size.400}",
        type: "fontSizes",
      },
    },
    "line-height": {
      "x-small": {
        value: "{line-height.200}",
        type: "lineHeights",
      },
      small: {
        value: "{line-height.250}",
        type: "lineHeights",
      },
      standard: {
        value: "{line-height.300}",
        type: "lineHeights",
      },
      large: {
        value: "{line-height.350}",
        type: "lineHeights",
      },
      "x-large": {
        value: "{line-height.400}",
        type: "lineHeights",
      },
    },
    "font-weight": {
      regular: {
        value: "{font-weight.primary.regular}",
        type: "fontWeights",
      },
      bold: {
        value: "{font-weight.primary.bold}",
        type: "fontWeights",
      },
    },
    "font-family": {
      default: {
        value: "{font-family.primary}",
        type: "fontFamilies",
      },
    },
  },
  "consumer-web": {},
  $themes: [],
};
